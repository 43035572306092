import { useState } from "react";
import ProjectList from "./ProjectList";

const Projects = () => {
  const [projects, setProjects] = useState([
    {
      title: "SuiteSnippets",
      description:
        "SuiteSnippets is an extension for VSCode to simplify writing SuiteScript, a language used within NetSuite.",
      link:
        "https://marketplace.visualstudio.com/items?itemName=ericbirdsall.SuiteSnippets",
      imageurl:
        "https://raw.githubusercontent.com/erjobi/SuiteSnippets/master/icon.png",
      id: 1,
    },
  ]);

  return (
    <div id="projects" className="projects">
      <ProjectList projects={projects} />
    </div>
  );
};

export default Projects;
