const Navbar = () => {
  return (
    <nav className="navbar">
      <a href="#root">
        <h1>eric birdsall</h1>
      </a>
      <div className="links">
        <a href="#projects">projects</a>
        <a
          href="#contact"
          style={{
            color: "var(--accent)",
            backgroundColor: "var(--secondary)",
            borderRadius: "8px",
            fontWeight: "bold",
          }}
        >
          contact
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
