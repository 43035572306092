import Contact from "./Contact";
import Navbar from "./Navbar";
import Projects from "./Projects";
import Typewriter from "typewriter-effect";

function App() {
  return (
    <div className="app">
      <Navbar />
      <div className="content">
        <Contact />
        <Projects />
        {/* <Typewriter
          options={{
            strings: [
              "<center><b>LEARN</b></center>",
              "<center><b>CREATE</b></center>",
              "<center><b>REPEAT</b></center>",
            ],
            cursor: "",
            autoStart: true,
            loop: true,
          }}
        /> */}
      </div>
    </div>
  );
}

export default App;
