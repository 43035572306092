const ProjectList = ({ projects, title }) => {
  // const blogs = props.blogs;
  // const title = props.title;
  // console.log(blogs);

  return (
    <div className="project-list">
      <h1 className="header">projects</h1>
      {projects.map((project) => {
        return project.imageurl ? (
          <div className="project-preview" key={project.id}>
            <a href={project.link} target="_blank" rel="noreferrer">
              <img
                src={project.imageurl}
                alt={project.title}
                className="project-image"
              />
            </a>
            <br />
            <br />
            <h2 className="project-link">
              <a href={project.link} className="project-link">
                {project.title}
              </a>
            </h2>

            <p>{project.description}</p>
          </div>
        ) : (
          <div className="project-preview" key={project.id}>
            <h2 className="project-link">
              <a
                href={project.link}
                target="_blank"
                rel="noreferrer"
                className="project-link"
              >
                {project.title}
              </a>
            </h2>
            <p>{project.description}</p>
          </div>
        );
      })}
    </div>
  );
};

export default ProjectList;
