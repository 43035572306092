const Contact = () => {
  return (
    <div id="contact" className="contact">
      <h1 className="header">contact</h1>
      <br />
      <a href="mailto:%20eric@ericbirdsall.dev">eric@ericbirdsall.dev</a>
      <br />
      <br />
      <a
        href="mailto:%20eric@ericbirdsall.dev?subject=Let%27s%20Work%20Together"
        className="email-button"
      >
        email me
      </a>
      <br />
      <br />
      <p>
        I'm a self taught programmer originally from New York. I have a passion
        for making tools to help others.
      </p>
    </div>
  );
};

export default Contact;
